import GoogleMapComponent from '@wix/thunderbolt-elements/src/components/GoogleMap/viewer/GoogleMap';


const GoogleMap = {
  component: GoogleMapComponent
};


export const components = {
  ['GoogleMap']: GoogleMap
};

